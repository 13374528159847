import { useState } from "react";
import RedirectUrlContext from "#contexts/RedirectUrlContext";
import propTypes from "prop-types";

const RedirectUrlProvider = ({ children }) => {
  const [redirectUrl, setRedirectUrl] = useState(null);

  return (
    <RedirectUrlContext.Provider value={{ redirectUrl, setRedirectUrl }}>
      {children}
    </RedirectUrlContext.Provider>
  );
};

RedirectUrlProvider.propTypes = {
  children: propTypes.object,
};

export default RedirectUrlProvider;
