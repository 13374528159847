import React from "react";

// コンポーネント用部分適用関数
// 2024/03/11 現在利用していないが、今後providerが増え呼び出す可能性があるため記載。望月
export const partial =
  (Component, props) =>
  ({ children }) => {
    React.createElement(Component, props, [children]);
  };

// コンポーネント用合成関数
export const pipeComponents = (...components) => {
  console.log(components);
  return ({ children }) => {
    return components.reduce((Acc, Cur) => {
      return React.createElement(Cur, null, Acc);
    }, children);
  };
};
