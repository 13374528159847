import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth } from "aws-amplify";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import IsInternalContext from "#contexts/IsInternalContext";
import { TextField, Button, Card, CardContent } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import prefData from "#components/locals/Ja";
import { RingSpinnerOverlay } from 'react-spinner-overlay';

const ForgetPassword = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const { isInternal, setIsInternal } = useContext(IsInternalContext);
  const [error, setError] = useState(null); // エラー有無
  const refFirstRef = useRef(true);

  /* ローディングアニメーションの表示、非表示 */
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem("isInternal", isInternal);
    });

    if (process.env.NODE_ENV === "development") {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        if (isInternal === null) {
          if (sessionStorage.getItem("isInternal") === "true") {
            setIsInternal(true);
          } else {
            setIsInternal(false);
          }
          sessionStorage.removeItem("isInternal");
        }
      }
    }
  }, []);

  /**
   * @summary Cognito に登録済みのメールアドレス宛に「認証コード」を送信する。
   * @description Cognito に登録されたメールアドレス宛に「認証コード」が送信される。
   * @see {@link https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#forgot-password}
   * Note: ユーザ名を入力した場合でも、Cognito に登録されたメールアドレス宛に「認証コード」が送信される。
   * Note: 認証コード」と「OTP」は別物である。「認証コード」= Cognito がデフォルトで提供する機能。「OTP」は CUSTOM_AUTH で提供する機能である。
   */
  const handleSendConfirmCode = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      if (username === "") {
        setError(prefData.common.message.login.usernameEmail);
        setIsLoading(false);
        return;
      }
      console.log(`[DEBUG][ForgetPassword] username => ${username}`);
      await Auth.forgotPassword(username);
      setIsLoading(false);
      navigate("/change-password", { state: { username } });
    } catch (err) {
      console.error("Error sending OTP:", err);
      setIsLoading(false);
      if (err.code === "LimitExceededException") {
        setError(prefData.common.message.login.LimitChangePassword);
      } else if (err.code === "InvalidParameterException") {
        setError(prefData.common.message.login.invalidUsername);
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <form onSubmit={handleSendConfirmCode} className="app">
      <Helmet>
        <title>パスワードリセット(コード送信)画面</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent>
          {error && <div className="error-div">{error}</div>}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              padding: "16px",
              justifyContent: "center",
            }}
          >
            <AccountCircle sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label="EメールアドレスまたはユーザーID"
              variant="standard"
              name="username"
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => setUsername(e.target.value)}
            />
          </CardContent>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "300px", height: "30px", marginTop: "20px" }}
        >
          コードを送信
        </Button>
        <CardContent sx={{ fontSize: "12px" }}>
          {isInternal && <Link to="/">サインイン画面へ戻る</Link>}
          {!isInternal && <Link to="/external/">サインイン画面へ戻る</Link>}
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}>
      </RingSpinnerOverlay>
      <Footer fontSize="12px" />
    </form>
  );
};

export default ForgetPassword;
