import CognitoUserProvider from "#providers/CognitoUserProvider";
import PasswordProvider from "#providers/PasswordProvider";
import RedirectUrlProvider from "#providers/RedirectUrlProvider";
import SiteProvider from "#providers/SiteProvider";
import IsInternalProvider from "#providers/IsInternalProvider";
import SystemcodeProvider from "#providers/SystemcodeProvider";
import "#App.css";
import SignIn from "#components/pages/SignIn";
import ForgetPassword from "#components/pages/ForgetPassword";
import ChangePassword from "#components/pages/ChangePassword";
import SignUp from "#components/pages/SignUp";
import MFA from "#components/pages/MultiFactorAuthentication";
import NewPasswordRequired from "#components/pages/NewPasswordRequired";
import CompleteSignIn from "#components/pages/CompleteSignIn";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { pipeComponents } from "#providers/PipeComponents";
import AccessError from "#components/pages/AccessError";

function App() {
  function getQueryParameter(parameterName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameterName);
  }

  // QueryString からパラメータを取得する
  const mode = getQueryParameter("mode") || "";
  const redirectUrl = getQueryParameter("redirectUrl") || "";
  const site = getQueryParameter("site") || "";
  const systemcode = getQueryParameter("systemcode") || "";
  const Provider = pipeComponents(
    RedirectUrlProvider,
    SiteProvider,
    IsInternalProvider,
    SystemcodeProvider,
    CognitoUserProvider,
    PasswordProvider,
  );
  const content = (
    <div className="app">
      <Provider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <SignIn
                  redirectUrl={redirectUrl}
                  site={site}
                  mode={mode}
                  systemcode={systemcode}
                  isInternal={true}
                />
              }
            />
            <Route path="/input-otp" element={<MFA />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/new-password" element={<NewPasswordRequired />} />
            <Route path="/signedin" element={<CompleteSignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/external/"
              element={
                <SignIn
                  redirectUrl={redirectUrl}
                  site={site}
                  mode={mode}
                  systemcode={systemcode}
                  isInternal={false}
                />
              }
            />
            <Route path="/access-error" element={<AccessError />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
  return content;
}

export default App;
// export default withAuthenticator(App, { includeGreetings: true });
