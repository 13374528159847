import { useState, useLayoutEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth, Amplify } from "aws-amplify";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import CognitoUserContext from "#contexts/CognitoUserContext";
import PasswordContext from "#contexts/PasswordContext";
import RedirectUrlContext from "#contexts/RedirectUrlContext";
import SiteContext from "#contexts/SiteContext";
import SystemcodeContext from "#contexts/SystemcodeContext";
import IsInternalContext from "#contexts/IsInternalContext";
import {
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import {
  AccountCircle,
  Visibility,
  VisibilityOff,
  Lock,
} from "@mui/icons-material";
import prefData from "#components/locals/Ja";
import propTypes from "prop-types";
import awsExports from "#aws-exports";
import awsExportsEx from "#AwsExportsExternal";
import customAuth from "#CustomAuth";
import { RingSpinnerOverlay } from 'react-spinner-overlay';

const SignIn = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null); // エラー有無
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [webClientId, setWebClientId] = useState("");
  const { setCognitoUser } = useContext(CognitoUserContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const { password, setPassword } = useContext(PasswordContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const { redirectUrl, setRedirectUrl } = useContext(RedirectUrlContext);
  const { site, setSite } = useContext(SiteContext);
  const { setIsInternal } = useContext(IsInternalContext);
  const { systemcode, setSystemcode } = useContext(SystemcodeContext);

  /* ローディングアニメーションの表示、非表示 */
  const [ isLoading, setIsLoading ] = useState(false);

  /* パスワードの表示、非表示 */
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* 入力チェック */
  const checkInput = () => {
    setUsernameError("");
    setPasswordError("");
    if (username === "") {
      setUsernameError(prefData.common.message.login.usernameEmail);
    } else if (password === "") {
      setPasswordError(prefData.common.message.login.password);
    } else {
      return true;
    }
  };

  /**
   * @summary CompleteSignIn.jsx コンポーネントで referrer の値を取得するための設定処理である。
   * @description document.referrer はページ遷移をするたびに変化するので、次の要領で初回アクセス時の referrer を保存するようにした。
   *  01. SignIn.jsx が初めてレンダリングされるとき、referrer は null or '' である。
   *  02. 上記 01 の場合は、useEffectフック内の条件 if (!referrer) が true となり、document.referrer の値が setReferrer を使って保存される。
   *  03. referrer が一度セットされた後は、以降のレンダリングで useEffect フックが実行されても、referrer の状態は更新されなくなる。
   */
  useLayoutEffect(() => {
    // 社内外部判定
    if (props.isInternal) {
      const fixedConfig = { ...awsExports, ...customAuth };
      Amplify.configure(fixedConfig);
      setIsInternal(true);
      setWebClientId(awsExports.aws_user_pools_web_client_id);
    } else {
      const fixedConfig = { ...awsExportsEx, ...customAuth };
      Amplify.configure(fixedConfig);
      setIsInternal(false);
      setWebClientId(awsExportsEx.aws_user_pools_web_client_id);
    }

    if (!redirectUrl) {
      setRedirectUrl(props.redirectUrl);
    }
    if (!site) {
      setSite(props.site);
    }
    if (!systemcode) {
      setSystemcode(props.systemcode);
    }

    if (props.mode === "signOut") {
      try {
        Auth.signOut();
        localStorage.removeItem("idToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("username");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken.payload.exp");
        console.log("サインアウト完了");
      } catch (err) {
        console.error("Error signing out:", err);
      }
    } else if (props.mode === "signIn") {
      isSignedIn();
    }
  }, []);

  /**
   * ログイン中か判定
   */
  const isSignedIn = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      const session = await Auth.userSession(currentAuthUser);

      if (!session?.isValid()) {
        console.log("セッションが無効です");
      } else {
        // アクセストークン
        localStorage.setItem("accessToken", session.accessToken.jwtToken);
        // IDトークン
        localStorage.setItem("idToken", session.idToken.jwtToken);
        // 更新トークン
        localStorage.setItem("refreshToken", session.refreshToken.token);
        // ユーザー名
        localStorage.setItem("username", session.accessToken.payload.username);
        navigate("/signedin");
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @summary LocalStorage に OIDC Token を保存したうえで、サインインをする
   * @description LocalStorage に OIDC Token も保存する。
   * @see {@link https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in}
   */
  const handleSignIn = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (checkInput() !== true) {
      setIsLoading(false);
      return;
    }

    const deviceKey = localStorage.getItem(
      `CognitoIdentityServiceProvider.${webClientId}.${username}.deviceKey`,
    );

    try {
      /**
       * 下記リンクより、Auth.signIn の引数 username には、ユーザ名とメールアドレスのどちらを設定しても良いと判断した。
       * @see {@link https://github.com/aws-amplify/amplify-js/blob/f0df916a9/packages/auth/src/Auth.ts#L634}
       * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#signin}
       */
      const user = await Auth.signIn(username, password, { deviceKey });
      setCognitoUser(user);
      if (user.challengeName === "CUSTOM_CHALLENGE") {
        if (!user.challengeParam || !user.challengeParam.email) {
          setUsernameError(prefData.common.message.login.unverifiedEmail);
          setIsLoading(false);
          return;
        }
        if (user.challengeParam.isDevice === "true") {
          localStorage.removeItem(
            `CognitoIdentityServiceProvider.${webClientId}.${username}.deviceKey`,
          );
          localStorage.removeItem(
            `CognitoIdentityServiceProvider.${webClientId}.${username}.deviceGroupKey`,
          );
          localStorage.removeItem(
            `CognitoIdentityServiceProvider.${webClientId}.${username}.randomPasswordKey`,
          );
          delete user.deviceGroupKey;
          delete user.deviceKey;
          delete user.randomPassword;
          setCognitoUser(user);
        }
        setUsername(""); // username をクリア
        /**
         * Note: ここでの password クリアは厳禁である。
         *       理由は、(OTPの)「再送信」(MultiFactorAuthentication.jsx) 時に password が空になり失敗するためである。
         */
        setIsLoading(false);
        navigate("/input-otp");
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setIsLoading(false);
        navigate("/new-password");
      } else {
        // デバイス認証成功によりMFAスキップ
        localStorage.setItem(
          "accessToken",
          user.signInUserSession.accessToken.jwtToken,
        );
        // IDトークン
        localStorage.setItem(
          "idToken",
          user.signInUserSession.idToken.jwtToken,
        );
        // 更新トークン
        localStorage.setItem(
          "refreshToken",
          user.signInUserSession.refreshToken.token,
        );
        // ユーザー名
        localStorage.setItem(
          "username",
          user.signInUserSession.accessToken.payload.username,
        );
        setIsLoading(false);
        navigate("/signedin");
      }
    } catch (err) {
      if (err.code === "NotAuthorizedException") {
        if (err.message === "Incorrect username or password.") {
          err.message = prefData.common.message.login.NotAuthorizedException;
        } else if (err.message === "Password attempts exceeded") {
          err.message = prefData.common.message.login.LimitLoginChallenge;
        }
      } else if (err.code === "UserNotConfirmedException") {
        err.message = prefData.common.message.login.UserNotConfirmedException;
      } else if (err.code === "InvalidParameterException") {
        err.message = prefData.common.message.login.invalidUsername;
      }
      setError(err);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSignIn} className="app">
      <Helmet>
        <title>サインイン画面</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent style={{ padding: "16px" }}>
          {error && <div className="error-div">{error.message}</div>}
          {usernameError && <div className="error-div">{usernameError}</div>}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <AccountCircle sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label="EメールアドレスまたはユーザーID"
              variant="standard"
              name="username"
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </CardContent>

          {passwordError && <div className="error-div">{passwordError}</div>}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label="パスワード"
              variant="standard"
              name="password"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>
        </CardContent>

        <CardContent style={{ padding: "0 0 0 140px", fontSize: "14px" }}>
          <Link to="/forget-password">パスワードを忘れた場合</Link>
        </CardContent>
        <CardContent>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "300px", height: "30px" }}
          >
            サインイン
          </Button>
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}>
      </RingSpinnerOverlay>
      <Footer fontSize="12px" />
    </form>
  );
};

SignIn.propTypes = {
  redirectUrl: propTypes.string,
  site: propTypes.string,
  systemcode: propTypes.string,
  mode: propTypes.string,
  isInternal: propTypes.string,
};

export default SignIn;
