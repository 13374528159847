import "#App.css";
import { CardMedia } from "@mui/material";
import propTypes from "prop-types";
import YrcLogo from "#common/img/YrcLogo.png";
const Header = (props) => {
  return (
    <CardMedia
      component="img"
      image={YrcLogo}
      style={{ width: props.w }}
      className="header-logo"
    />
  );
};

Header.propTypes = {
  w: propTypes.any.isRequired,
};

export default Header;
