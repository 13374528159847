import { useState } from "react";
import SystemcodeContext from "#contexts/SystemcodeContext";
import propTypes from "prop-types";

const SystemcodeProvider = ({ children }) => {
  const [systemcode, setSystemcode] = useState(null);

  return (
    <SystemcodeContext.Provider value={{ systemcode, setSystemcode }}>
      {children}
    </SystemcodeContext.Provider>
  );
};

SystemcodeProvider.propTypes = {
  children: propTypes.object,
};

export default SystemcodeProvider;
