import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth } from "aws-amplify";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import IsInternalContext from "#contexts/IsInternalContext";
import CognitoUserContext from "#contexts/CognitoUserContext";
import {
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import prefData from "#components/locals/Ja";
import { RingSpinnerOverlay } from 'react-spinner-overlay';

const NewPasswordRequired = (props) => {
  const navigate = useNavigate();
  const { cognitoUser } = useContext(CognitoUserContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const [password, setPassword] = useState(""); // コンポーネント間でのデータ共有のために Context API を使用している
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(null);
  const { isInternal, setIsInternal } = useContext(IsInternalContext);
  const refFirstRef = useRef(true);
  /* パスワードの表示、非表示 */
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* ローディングアニメーションの表示、非表示 */
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem("isInternal", isInternal);
    });

    if (process.env.NODE_ENV === "development") {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        if (isInternal === null) {
          if (sessionStorage.getItem("isInternal") === "true") {
            setIsInternal(true);
          } else {
            setIsInternal(false);
          }
          sessionStorage.removeItem("isInternal");
        }
      }
    }
  }, []);

  const clear = () => {
    setErrorPassword("");
    setErrorPasswordConfirm("");
    setError("");
  };

  /* 入力チェック */
  const checkInput = () => {
    clear();
    if (password === "") {
      setErrorPassword(prefData.common.message.login.password);
    } else if (passwordConfirm === "") {
      setErrorPasswordConfirm(prefData.common.message.login.passwordConfirm);
    } else if (password !== passwordConfirm) {
      setErrorPassword(prefData.common.message.login.passwordMisMatch);
    } else {
      return true;
    }
  };

  /**
   * @summary LocalStorage に OIDC Token を保存したうえで、サインインをする
   * @description LocalStorage に OIDC Token も保存する。
   * @see {@link https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in}
   */
  const handleNewPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (checkInput() !== true) {
      setIsLoading(false);
      return;
    }
    if (!cognitoUser) {
      if (isInternal === true) {
        navigate("/");
      } else {
        navigate("/external/");
      }
      return;
    }
    try {
      /**
       * 下記リンクより、Auth.signIn の引数 username には、ユーザ名とメールアドレスのどちらを設定しても良いと判断した。
       * @see {@link https://github.com/aws-amplify/amplify-js/blob/f0df916a9/packages/auth/src/Auth.ts#L1236}
       * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#completenewpassword}
       */
      await Auth.completeNewPassword(cognitoUser, password);
      await Auth.forgetDevice();
      await Auth.signOut();
      if (isInternal === true) {
        setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        navigate("/external/");
      }
    } catch (err) {
      setError(prefData.common.message.login.passwordValid);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleNewPassword} className="app">
      <Helmet>
        <title>仮パスワード変更画面</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent sx={{ padding: "16px" }}>
          {error && <div className="error-div">{error}</div>}
          {errorPassword && <div className="error-div">{errorPassword}</div>}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              placeContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label="新パスワード"
              variant="standard"
              name="password"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>

          {errorPasswordConfirm && (
            <div className="error-div">{errorPasswordConfirm}</div>
          )}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              placeContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label="パスワード再入力"
              variant="standard"
              name="passwordConfirm"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              type={showPasswordConfirm ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle passwordConfirm visibility"
              onClick={handleClickShowPasswordConfirm}
              onMouseDown={handleMouseDownPassword}
            >
              {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "300px", height: "30px" }}
        >
          パスワード変更
        </Button>
        <CardContent sx={{ fontSize: "12px" }}>
          {isInternal && <Link to="/">サインイン画面へ戻る</Link>}
          {!isInternal && <Link to="/external/">サインイン画面へ戻る</Link>}
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}>
      </RingSpinnerOverlay>
      <Footer fontSize="14px" />
    </form>
  );
};

export default NewPasswordRequired;
