import { Helmet } from "react-helmet";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import { Card, CardContent } from "@mui/material";

const AccessError = () => {
  return (
    <form className="app">
      <Helmet>
        <title>アクセス権限エラー</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="290px" />
        <CardContent style={{ padding: "50px" }}>
          <div className="access-error-div">
            遷移元のアプリの利用権限がありません
          </div>
          <div className="access-error-div">
            他のユーザーでサインインしたい場合は、
          </div>
          <div className="access-error-div">
            ログイン中のアプリからサインアウトしてください。
          </div>
        </CardContent>
      </Card>
      <Footer fontSize="12px" />
    </form>
  );
};

export default AccessError;
