import { useLayoutEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import RedirectUrlContext from "#contexts/RedirectUrlContext";
import SiteContext from "#contexts/SiteContext";
import SystemcodeContext from "#contexts/SystemcodeContext";
import axios from "axios";
import "#App.css";
import { Card, CardContent } from "@mui/material";
import { constant } from "#components/data/Constant";
import IsInternalContext from "#contexts/IsInternalContext";
import { useNavigate } from "react-router-dom";

const CompleteSignIn = () => {
  const navigate = useNavigate();
  const { redirectUrl } = useContext(RedirectUrlContext);
  const { site } = useContext(SiteContext);
  const { systemcode } = useContext(SystemcodeContext);
  const { isInternal } = useContext(IsInternalContext);

  /**
   * アクセス権限の判定
   */
  const isAccessPrivileges = async (username) => {
    const res = await getKey();
    return await axios({
      method: "get",
      url: constant.PERSONAL_INFO_API + username,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "yrc-api-key": res.apiKey,
        "yrc-secret-key": res.secretKey,
      },
    })
      .then((response) => {
        const uasList = response.data.user_available_systems;
        if (uasList.length) {
          const filterUasList = uasList.filter(
            (uas) => uas.system_code === systemcode,
          );
          if (filterUasList.find((uas) => uas.is_valid === true)) {
            console.log(
              username + "を" + systemcode + "へのアクセスを許可します。",
            );
            return true;
          }
        }
        console.log(username + "は" + systemcode + "へのアクセス不可です。");
        return false;
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        return false;
      });
  };

  const getKey = async () => {
    return await axios({
      method: "post",
      url: constant.GET_KEY_API,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        return false;
      });
  };

  /**
   * @summary OIDC Token を共用するために、クライアントのエンドポイント「/callback」に OIDC Token を送信する。
   * @details CompleteSignIn がマウントされたとき (= 初回のみ)実行するように、useEffect(～, []) としている。
   */
  useLayoutEffect(() => {
    (async () => {
      const accessToken = localStorage.getItem("accessToken");
      const idToken = localStorage.getItem("idToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const username = localStorage.getItem("username");

      if (isInternal) {
        const res = await isAccessPrivileges(username);
        if (!res) {
          navigate("/access-error");
          return;
        }
      }

      let callbackUrl = redirectUrl;
      if (callbackUrl) {
        if (site === "st") {
          /** 静的ホスティングのクライアントアプリの場合 */
          callbackUrl =
            callbackUrl +
            `?accessToken=${encodeURIComponent(accessToken)}` +
            `&idToken=${encodeURIComponent(idToken)}` +
            `&refreshToken=${encodeURIComponent(refreshToken)}` +
            `&username=${username}` +
            "#/callback";
          window.location.href = callbackUrl;
        } else if (site === "dy") {
          /** 動的ホスティング */
          // form を動的に生成
          const form = document.createElement("form");
          form.action = callbackUrl + "callback";
          form.method = "POST";
          // body に追加
          document.body.append(form);

          for (let i = 0; i < localStorage.length; i++) {
            const inputElement = document.createElement("input");
            const key = localStorage.key(i);
            inputElement.name = key;
            inputElement.value = localStorage.getItem(key);
            inputElement.style.display = "none";
            form.appendChild(inputElement);
          }
          form.submit();
        } else {
          callbackUrl = "/";
          window.location.href = callbackUrl;
        }
      }
    })();
  }, []);

  return (
    <div className="app">
      <Helmet>
        <title>サインイン完了画面</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1, width: "470px" }}>
        <Header w="290px" />
        <CardContent
          style={{ fontSize: "14px", marginBottom: "40px", marginTop: "10px" }}
        >
          サインインが正常に完了しました。
        </CardContent>
      </Card>
      <Footer fontSize="14px" />
    </div>
  );
};

export default CompleteSignIn;
