import { useState } from "react";
import IsInternalContext from "#contexts/IsInternalContext";
import propTypes from "prop-types";

const IsInternalProvider = ({ children }) => {
  const [isInternal, setIsInternal] = useState(null);

  return (
    <IsInternalContext.Provider value={{ isInternal, setIsInternal }}>
      {children}
    </IsInternalContext.Provider>
  );
};

IsInternalProvider.propTypes = {
  children: propTypes.object,
};

export default IsInternalProvider;
