import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth } from "aws-amplify";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import PasswordContext from "#contexts/PasswordContext";
import {
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
  Link,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CardHeader,
} from "@mui/material";
import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
  Mail,
  Phone,
  Article,
} from "@mui/icons-material";
import prefData from "#components/locals/Ja";

/**
 * @summary 引数で与えられたメールアドレスの一部をマスクして返却する。
 *
 * @function
 * @name maskEmail
 * @param {string} email - マスクされるメールアドレス。
 * @param {number} [mask_interval=4] - メールアドレスのマスク間隔。
 * @param {string} [mask_char='*'] - メールアドレスをマスクする際に使用する文字。
 * @returns {string} マスクされたメールアドレス。
 * @example
 * maskEmail("taro.yamada@example.com"); // tar*.yam*da@exa*ple.*om
 * maskEmail("taro.yamada@example.com", 3, '#'); // ta#o.y#ma#a@ex#mp*e.c#m
 */
function maskEmail(email, maskInterval = 4, maskChar = "*") {
  if (!email) return "";

  let result = "";
  let count = 0;

  for (let i = 0; i < email.length; i++) {
    if (email[i] === "@") {
      result += email[i];
      count = 0;
    } else if (email[i] === "." || email[i] === "-" || email[i] === "_") {
      result += email[i];
    } else {
      count++;
      if (count === maskInterval) {
        result += maskChar;
        count = 0;
      } else {
        result += email[i];
      }
    }
  }

  return result;
}

const SignUp = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const { password, setPassword } = useContext(PasswordContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const [onetimePass, setOnetimePass] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState(null); // エラー有無
  const [confirmationCode] = useState("");
  const [setResetPasswordCode] = useState("");
  const [confirmationVisible, setConfirmationVisible] = useState(false); // 「確認コード」入力モードか否か
  /* パスワードの表示、非表示 */
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* 電話番号 */
  const [tel, setTel] = React.useState("");
  const handleChange = (event) => {
    setTel(event.target.value);
  };

  /**
   * @summary サインアップ(新規アカウント作成)をする
   * @see {@link https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-up }
   * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#signup}
   */
  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      setEmailAddress(maskEmail(email));
      await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional
        },
      });
      setConfirmationVisible(true); // Sign Up成功後に確認コード入力画面を表示する
    } catch (err) {
      console.error("error signing up:", err);
    }
  };

  /** テキスト入力欄の name 属性に応じて、動的に username と password の入力場所を選択する */
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
  };

  /**
   * @summary サインアップ(新規アカウント作成)をする
   * @see {@link https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#re-send-sign-up-confirmation-code}
   * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#confirmsignup}
   * Note: 「ユーザー名 or Email」と「確認コード」の両方を同時入力する。
   */
  const handleConfirmSignUp = async (event) => {
    event.preventDefault();
    try {
      if (onetimePass === "") {
        setError(prefData.common.message.login.confirmationCode);
        return;
      }
      await Auth.confirmSignUp(username, confirmationCode);
      setConfirmationVisible(false); // 確認成功後に確認画面を非表示にする
      navigate("/");
    } catch (err) {
      console.error("ErrorLog:", err);
      if (err.code === "ExpiredCodeException") {
        err.message = prefData.common.message.login.ExpiredCodeException;
      } else if (err.code === "CodeMismatchException") {
        err.message = prefData.common.message.login.CodeMismatchException;
      }
      setError(err.message);
    }
  };

  const resend = async () => {
    try {
      /**
       * 下記リンクより、Auth.signIn の引数 username には、ユーザ名とメールアドレスのどちらを設定しても良いと判断した。
       * @see {@link https://github.com/aws-amplify/amplify-js/blob/f0df916a9/packages/auth/src/Auth.ts#L634}
       * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#signin}
       * Note: password は Signin.jsx で設定された値を利用する。
       */
      await Auth.resendSignUp({ username });
    } catch (err) {
      console.error("ErrorLog: ", err);
      setError(err.message);
    }
  };

  let content;
  if (!confirmationVisible) {
    content = (
      <form onSubmit={handleSignUp} className="app">
        <Helmet>
          <title>サインアップ画面</title>
        </Helmet>
        <Card className="forms" sx={{ boxShadow: 1 }}>
          <Header w="240px" />
          <CardContent sx={{ padding: "16px" }}>
            <CardContent
              sx={{ display: "flex", alignItems: "flex-end", padding: "16px" }}
            >
              <AccountCircle sx={{ color: "action.active", mr: 2, my: 0.5 }} />
              <TextField
                id="standard-basic"
                label="ユーザーID"
                variant="standard"
                name="username"
                style={{ width: "300px", padding: "0 0 0 0" }}
                onChange={handleInputChange}
              />
            </CardContent>

            <CardContent sx={{ display: "flex", alignItems: "flex-end" }}>
              <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
              <TextField
                id="standard-adornment-password"
                label="パスワード"
                variant="standard"
                name="password"
                style={{ width: "260px", padding: "0 0 0 0" }}
                onChange={handleInputChange}
                type={showPassword ? "text" : "password"}
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </CardContent>

            <CardContent sx={{ display: "flex", alignItems: "flex-end" }}>
              <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
              <TextField
                id="standard-adornment-password"
                label="パスワード再入力"
                variant="standard"
                name="passwordConfirm"
                style={{ width: "260px", padding: "0 0 0 0" }}
                type={showPasswordConfirm ? "text" : "password"}
              />
              <IconButton
                aria-label="toggle passwordConfirm visibility"
                onClick={handleClickShowPasswordConfirm}
                onMouseDown={handleMouseDownPassword}
              >
                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </CardContent>

            <CardContent sx={{ display: "flex", alignItems: "flex-end" }}>
              <Mail sx={{ color: "action.active", mr: 2, my: 0.5 }} />
              <TextField
                id="standard-basic"
                label="Eメールアドレス"
                variant="standard"
                name="email"
                style={{ width: "300px", padding: "0 0 0 0" }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </CardContent>

            <CardContent sx={{ display: "flex", alignItems: "flex-end" }}>
              <Phone sx={{ color: "action.active", mr: 2, my: 0.5 }} />
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 50, margin: "0 0 0 0" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  国
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={tel}
                  onChange={handleChange}
                  label="Tel"
                >
                  <MenuItem value={81}>+81</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="standard-basic"
                label="電話番号"
                variant="standard"
                name="resetPasswordCode"
                style={{ width: "240px", margin: "0 0 0 10px" }}
                onChange={(e) => setResetPasswordCode(e.target.value)}
              />
            </CardContent>
          </CardContent>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "300px", height: "30px" }}
          >
            サインアップ
          </Button>
          <CardContent sx={{ fontSize: "12px" }}>
            <Link href="/">サインイン画面へ戻る</Link>
          </CardContent>
        </Card>
        <Footer fontSize="14px" />
      </form>
    );
  } else if (confirmationVisible) {
    content = (
      <form onSubmit={handleConfirmSignUp} className="app">
        <Helmet>
          <title>本人確認画面</title>
        </Helmet>
        <Card className="forms" sx={{ boxShadow: 1 }}>
          <Header w="240px" />
          <CardHeader
            title="確認コードが送信されました。"
            titleTypographyProps={{ variant: "h6" }}
            subheader={props.email}
            subheaderTypographyProps={{ variant: "h6" }}
          ></CardHeader>

          {!error && <div className="success-div">{emailAddress}</div>}
          {error && <div className="error-div">{error}</div>}
          <CardContent sx={{ display: "flex", alignItems: "flex-end" }}>
            <Article sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label="確認コード"
              variant="standard"
              name="onetimePass"
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setOnetimePass(e.target.value);
              }}
            />
          </CardContent>

          <CardContent>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "300px", height: "30px" }}
            >
              コード確認
            </Button>
          </CardContent>
          <CardContent sx={{ padding: 0 }}>
            <Button
              onClick={resend}
              variant="outlined"
              sx={{ width: "300px", height: "30px", boxShadow: 1 }}
            >
              再送信
            </Button>
          </CardContent>
          <CardContent sx={{ fontSize: "12px" }}>
            <Link href="/">サインイン画面へ戻る</Link>
          </CardContent>
        </Card>
        <Footer fontSize="14px" />
      </form>
    );
  }
  return content;
};

export default SignUp;
