import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import PasswordContext from "#contexts/PasswordContext";
import IsInternalContext from "#contexts/IsInternalContext";
import {
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
  Article,
} from "@mui/icons-material";
import prefData from "#components/locals/Ja";
import { RingSpinnerOverlay } from 'react-spinner-overlay';

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null); // Amplifyエラー有無
  /* 入力チェック用エラー */
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(null);
  const [errorConfirmationCode, setErrorConfirmationCode] = useState(null);
  /* 入力 */
  const [username, setUsername] = useState(location.state.username);
  const { isInternal, setIsInternal } = useContext(IsInternalContext);
  const { password, setPassword } = useContext(PasswordContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const refFirstRef = useRef(true);
  /* パスワードの表示、非表示 */
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* ローディングアニメーションの表示、非表示 */
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem("isInternal", isInternal);
    });

    if (process.env.NODE_ENV === "development") {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        if (isInternal === null) {
          if (sessionStorage.getItem("isInternal") === "true") {
            setIsInternal(true);
          } else {
            setIsInternal(false);
          }
          sessionStorage.removeItem("isInternal");
        }
      }
    }
  }, []);

  const clear = () => {
    setErrorPassword("");
    setErrorPasswordConfirm("");
    setErrorConfirmationCode("");
    setError("");
  };

  /* 入力チェック */
  const checkInput = () => {
    clear();
    if (password === "") {
      setErrorPassword(prefData.common.message.login.password);
    } else if (passwordConfirm === "") {
      setErrorPasswordConfirm(prefData.common.message.login.passwordConfirm);
    } else if (confirmationCode === "") {
      setErrorConfirmationCode(prefData.common.message.login.confirmationCode);
    } else if (password !== passwordConfirm) {
      setErrorPasswordConfirm(prefData.common.message.login.passwordMisMatch);
    } else {
      return true;
    }
  };

  /**
   * @summary OnetimePass (OTP) と 新規パスワードを入力する
   * @description OTP と 新規パスワード の両方を同時に入力すること。Amplify SDK では OTP のみの認証はサポートしていない。
   * @see {@link https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#forgot-password}
   * Note: Auth.forgotPasswordSubmit は username, OTP, 新規パスワード の 3つセットで使用する必要あり。
   */
  const handleConfirmOTPAndNewPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (checkInput() !== true) {
        setIsLoading(false);
        return;
      }
      await Auth.forgotPasswordSubmit(username, confirmationCode, password);
      if (isInternal) {
        setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        navigate("/external/");
      }
    } catch (err) {
      if (err.code === "InvalidPasswordException") {
        setError(prefData.common.message.login.passwordValid);
      } else if (err.code === "ExpiredCodeException") {
        setError(prefData.common.message.login.ExpiredCodeException);
      } else if (err.code === "CodeMismatchException") {
        setError(prefData.common.message.login.CodeMismatchException);
      } else if (err.code === "LimitExceededException") {
        setError(prefData.common.message.login.LimitChangePassword);
      }
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleConfirmOTPAndNewPassword} className="app">
      <Helmet>
        <title>パスワードリセット画面</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent sx={{ padding: "16px" }}>
          {error && <div className="error-div">{error}</div>}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              padding: "16px",
              justifyContent: "center",
            }}
          >
            <AccountCircle sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label="EメールアドレスまたはユーザーID"
              variant="standard"
              name="username"
              value={username}
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{ readOnly: true }}
            />
          </CardContent>

          {errorPassword && <div className="error-div">{errorPassword}</div>}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label="パスワード"
              variant="standard"
              name="password"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>

          {errorPasswordConfirm && (
            <div className="error-div">{errorPasswordConfirm}</div>
          )}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label="パスワード再入力"
              variant="standard"
              name="passwordConfirm"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              type={showPasswordConfirm ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle passwordConfirm visibility"
              onClick={handleClickShowPasswordConfirm}
              onMouseDown={handleMouseDownPassword}
            >
              {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>

          {errorConfirmationCode && (
            <div className="error-div">{errorConfirmationCode}</div>
          )}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Article sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label="認証コード"
              variant="standard"
              name="confirmationCode"
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </CardContent>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "300px", height: "30px" }}
        >
          パスワード変更
        </Button>
        <CardContent sx={{ fontSize: "12px" }}>
          {isInternal && <Link to="/">サインイン画面へ戻る</Link>}
          {!isInternal && <Link to="/external/">サインイン画面へ戻る</Link>}
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}>
      </RingSpinnerOverlay>
      <Footer fontSize="12px" />
    </form>
  );
};

export default ChangePassword;
