import { useState } from "react";
import CognitoUserContext from "#contexts/CognitoUserContext";
import propTypes from "prop-types";

const CognitoUserProvider = ({ children }) => {
  const [cognitoUser, setCognitoUser] = useState(null);
  return (
    <CognitoUserContext.Provider value={{ cognitoUser, setCognitoUser }}>
      {children}
    </CognitoUserContext.Provider>
  );
};

CognitoUserProvider.propTypes = {
  children: propTypes.any,
};

export default CognitoUserProvider;
