import { useState } from "react";
import PasswordContext from "#contexts/PasswordContext";
import propTypes from "prop-types";

const PasswordProvider = ({ children }) => {
  const [password, setPassword] = useState("");

  return (
    <PasswordContext.Provider value={{ password, setPassword }}>
      {children}
    </PasswordContext.Provider>
  );
};

PasswordProvider.propTypes = {
  children: propTypes.object,
};

export default PasswordProvider;
