import propTypes from "prop-types";
const Footer = (props) => {
  return (
    <footer
      style={{ margin: "20px", fontSize: props.fontSize, marginBottom: "20px" }}
    >
      Copyright © THE YOKOHAMA RUBBER CO., LTD. All rights reserved.
    </footer>
  );
};

Footer.propTypes = {
  fontSize: propTypes.any.isRequired,
};

export default Footer;
