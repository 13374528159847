import { useState } from "react";
import SiteContext from "#contexts/SiteContext";
import propTypes from "prop-types";

const SiteProvider = ({ children }) => {
  const [site, setSite] = useState(null);

  return (
    <SiteContext.Provider value={{ site, setSite }}>
      {children}
    </SiteContext.Provider>
  );
};

SiteProvider.propTypes = {
  children: propTypes.object,
};

export default SiteProvider;
