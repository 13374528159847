import React from "react";
import ReactDOM from "react-dom/client";
import "#index.css";
import App from "#App";
import reportWebVitals from "#reportWebVitals";
import "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Webブラウザ > F12 > 開発モード > console より、処理時間などが表示される
reportWebVitals(console.log);
