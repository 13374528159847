const constant = {
  // 個人情報APIエンドポイント
  PERSONAL_INFO_API:
    "https://ftlip2cd5l.execute-api.ap-northeast-1.amazonaws.com/v1/user/",
  // シークレットキー取得APIエンドポイント
  GET_KEY_API:
    "https://p2umh2jqb3.ap-northeast-1.awsapprunner.com/getSecretKey/",
};

export { constant };
